// extracted by mini-css-extract-plugin
export var activityDescription = "ActivitiesModule-module--activityDescription--0z3Hu";
export var activityTitle = "ActivitiesModule-module--activityTitle--HOBz3";
export var activityWrapper = "ActivitiesModule-module--activityWrapper--kJHx0";
export var component = "ActivitiesModule-module--component--MkVtH";
export var form = "ActivitiesModule-module--form--Mqe1z";
export var formInput = "ActivitiesModule-module--formInput--H3vQC";
export var image = "ActivitiesModule-module--image--Fjsun";
export var imageWrapper = "ActivitiesModule-module--imageWrapper--gypf6";
export var isCompact = "ActivitiesModule-module--isCompact--qZlGK";
export var overlay = "ActivitiesModule-module--overlay--vIrVK";
export var randomizeButton = "ActivitiesModule-module--randomizeButton--2sxck";
export var readMoreButton = "ActivitiesModule-module--readMoreButton--PJqri";
export var textsWrapper = "ActivitiesModule-module--textsWrapper--z4TUu";
export var title = "ActivitiesModule-module--title--D98lS";